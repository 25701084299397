<template>
	<Menu actual="/blog" class="mt-6" />

	<div class="py-16 overflow-hidden" v-if="state.loaded">
		<div class="max-w-4xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">

			<div class="text-base max-w-prose mx-auto lg:max-w-none">
                <router-link to="/blog">
                    <h2 class="text-xs text-vino font-semibold tracking-wide uppercase hover:text-azul">
                        <ArrowSmLeftIcon class="w-4 h-4 mr-1 inline-block mb-1" />
                        Listado del blog
                    </h2>
                </router-link>
				
				<!-- <h2 class="text-sm text-morado font-semibold tracking-wide uppercase mt-1">{{state.entry.date}}</h2> -->
				<p class="mt-1 text-3xl leading-8 font-extrabold tracking-tight text-cyan sm:text-4xl">{{state.entry.title}}</p>
                <img :src="state.entry.image" alt="" class="w-full object-cover max-h-56 mt-3 rounded">
			</div>

			<div class="relative z-10 text-base prose-xl mx-auto">
				<p class="text-base text-gray-500 leading-snug" v-html="richtextToHTML(state.entry.content)"></p>
			</div>

            <router-link to="/blog">
                <h2 class="text-xs text-vino font-semibold tracking-wide uppercase hover:text-azul mt-8">
                    <ArrowSmLeftIcon class="w-4 h-4 mr-1 inline-block mb-1" />
                    Listado del blog
                </h2>
            </router-link>
		</div>
	</div>
</template>
q
<script>
import Menu from '@/components/core/Menu'
import { onMounted, reactive } from 'vue';
import { useRoute } from 'vue-router'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { ArrowSmLeftIcon } from '@heroicons/vue/outline'

let contentful = require('contentful')
const client = contentful.createClient({
    space: 'ngc1yxicbwza',
    accessToken: 'sqPUI45ZMWM2WEEIOfgPtAaXQwclfTIsLoLQnPgvS08'
});

export default {
	components: {
		Menu,
        documentToHtmlString,
        ArrowSmLeftIcon
	},
	setup() {
        const route = useRoute();

		const state = reactive({
            route: route,
			loaded: false,
			entry: null
		});

		onMounted(() => {
			getEntries();
		});

		function getEntries() {
			client.getEntries({
                    'content_type': 'entryBlog',
                    'fields.slug': state.route.params.id
                }).then(response => {
                    state.entry = {
						id: response.items[0].sys.id, 
						title: response.items[0].fields.title, 
						slug: response.items[0].fields.slug, 
                        image: response.items[0].fields.image.fields.file.url,
						content: response.items[0].fields.content
					};

                    // let tempo = new Date(response.items[0].fields.date);
                    // state.entry.date = tempo.toLocaleString('es-MX', {year: 'numeric', month: 'long', day: 'numeric'});

				    state.loaded = true;
			});
		}

        function richtextToHTML(content) {
            return documentToHtmlString(content)
        }

		return {
			state,
            richtextToHTML
		}
	}
}
</script>

